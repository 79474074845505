/*
BUTTONS
*/

.button {
    vertical-align: middle;
    display: inline-block;
    padding: 0.45rem 2.5rem;
    text-align: center;
    user-select: none;
    @include transition((background-color, color), 350ms, ease-in-out);
    border: 2px solid transparent;
    &--primary {
        background-color: $brand-color-primary;
        color: #ffffff;
        &:hover {
            background-color: darken($brand-color-primary, 12%);
            color: #ffffff;
        }
    }
    &--primary-ghost {
        background-color: none;
        color: $brand-color-primary;
        border: 2px solid $brand-color-primary;
        &:hover {
            background-color: darken($brand-color-primary, 12%);
            color: #ffffff;
        }
    }
    &--secondary {
        background-color: $brand-color-secondary;
        color: #ffffff;
        &:hover {
            background-color: darken($brand-color-secondary, 12%);
            color: #ffffff;
        }
    }
    &--secondary-ghost {
        background-color: none;
        color: $brand-color-secondary;
        border: 2px solid $brand-color-secondary;
        &:hover {
            background-color: darken($brand-color-secondary, 12%);
            color: #ffffff;
        }
    }
    &--tertiary {
        background-color: $brand-color-tertiary;
        color: #ffffff;
        &:hover {
            background-color: darken($brand-color-tertiary, 12%);
            color: #ffffff;
        }
    }
    &--tertiary-ghost {
        background-color: none;
        color: $brand-color-tertiary;
        border: 2px solid $brand-color-tertiary;
        &:hover {
            background-color: darken($brand-color-tertiary, 12%);
            color: #ffffff;
        }
    }
    &--success {
        background-color: $success-background;
        color: $success-text-color;
        &:hover {
            background-color: darken($success-background, 12%);
            color: $success-text-color;
        }
    }
    &--success-ghost {
        background-color: none;
        color: $success-text-color;
        border: 2px solid $success-border;
        &:hover {
            background-color: darken($success-background, 12%);
            color: #ffffff;
        }
    }
    &--warning {
        background-color: $warning-background;
        color: $warning-text-color;
        &:hover {
            background-color: darken($warning-background, 12%);
            color: $warning-text-color;
        }
    }
    &--warning-ghost {
        background-color: none;
        color: $warning-text-color;
        border: 2px solid $warning-border;
        &:hover {
            background-color: darken($warning-background, 12%);
            color: #ffffff;
        }
    }
    &--danger {
        background-color: $danger-background;
        color: $danger-text-color;
        &:hover {
            background-color: darken($danger-background, 12%);
            color: $danger-text-color;
        }
    }
    &--danger-ghost {
        background-color: none;
        color: $danger-text-color;
        border: 2px solid $danger-border;
        &:hover {
            background-color: darken($danger-background, 12%);
            color: #ffffff;
        }
    }
    &--light {
        background-color: $light-grey;
        color: $dark-grey;
        &:hover {
            background-color: darken($light-grey, 12%);
            color: $dark-grey;
        }
    }
    &--light-ghost {
        background-color: none;
        color: $light-grey;
        border: 2px solid $light-grey;
        &:hover {
            background-color: $mid-grey;
            color: $dark-grey;
        }
    }
    &--dark {
        background-color: $dark-grey;
        color: $light-grey;
        &:hover {
            background-color: darken($dark-grey, 12%);
            color: $light-grey;
        }
    }
    &--dark-ghost {
        background-color: none;
        color: $dark-grey;
        border: 2px solid $dark-grey;
        &:hover {
            background-color: $dark-grey;
            color: $light-grey;
        }
    }
    &--pill {
        border-radius: 50px;
    }
}
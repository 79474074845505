/*
MODAL
*/

.modal {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.7);
    z-index: 1001;
    &__content {
        position: relative;
        background-color: $white;
        width: 60%;
        padding: 20px;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0px 0px 26px -1px rgba(48,48,48,1);
    }
    &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 25px;
        right: 25px;
        color: $white;
        font-size: 1.8rem;
        cursor: pointer;
    }
}

@include breakpoint(large) {
    .modal {
        &__content {
            width: 70%;
        }
    }
}

@include breakpoint(medium) {
    .modal {
        &__content {
            width: 80%;
        }
    }
}

@include breakpoint(small) {
    .modal {
        &__content {
            width: 90%;
        }
    }
}
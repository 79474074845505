// Grid settings

$grid-columns:    12;
$grid-width:      1240px;

$breakpoint-large:  "(max-width: 64em)";
$breakpoint-medium: "(max-width: 48em)";
$breakpoint-small:  "(max-width: 34em)";
$breakpoint-xsmall: "(max-width: 25em)";

// Colour scheme

$brand-color-primary:   #71A7C9;
$brand-color-secondary: #3B74A2;
$brand-color-tertiary:  #2E547A;

$light-grey: #FAFAFA;
$mid-grey:   #EEEEEE;
$dark-grey:  #303030;

$black: #000000;
$white: #ffffff;

$danger-background: #FFCDD2;
$danger-border:     darken($danger-background, 5%);
$danger-text-color: darken($danger-background, 50%);

$warning-background: #FFECB3;
$warning-border:     darken($warning-background, 6%);
$warning-text-color: darken($warning-background, 40%);

$success-background: #DCEDC8;
$success-border:     darken($success-background, 6%);
$success-text-color: darken($success-background, 40%);

// Typography

$body-font: Arial, Helvetica, sans-serif;
$heading-font: "Raleway", Helvetica, sans-serif;

$base-text-size:     16px;
$base-heading-size:  1rem;
$heading-scale:      .4; // percentage of rem each heading grows from h6 to h1
$text-bottom-margin: 1.2rem;

$body-text-color: #909090;
$headings-color:  #303030;

$link-color:       #909090;
$link-color-hover: $brand-color-primary;

// Navigation

$desktop-nav-font-size:     1rem;

$top-level-nav-color:       #ffffff;
$top-level-nav-color-hover: #909090;

$top-level-nav-color-scroll:       #303030;
$top-level-nav-color-scroll-hover: #909090;

$submenu-background-color:  #303030;

$submenu-text-color:        #909090;
$submenu-text-color-hover:  #ffffff;

// Mobile navigation

$mobile-nav-toggle:               #303030;
$mobile-nav-width:                65%;
$mobile-nav-color:                #909090;
$mobile-nav-background:           #ffffff;
$mobile-navigation-bottom-border: $mid-grey;

// Header

$header-background-color:        transparent;
$header-background-color-scroll: #ffffff;
$bottom-border:                  transparent;
$bottom-border-scroll:                  #efefef;

$header-size:         100px;
$header-size-scroll:  70px;

// Fonts

$ensemble-font-path: "../fonts" !default;

$ei-address: \e900;
$ei-calendar: \e901;
$ei-chevron-down: \e902;
$ei-chevron-left: \e903;
$ei-chevron-right: \e904;
$ei-chevron-up: \e905;
$ei-close: \e906;
$ei-comment: \e907;
$ei-email: \e908;
$ei-help: \e909;
$ei-magnifier: \e90a;
$ei-mobile-phone: \e90b;
$ei-nav-pip-active: \e90c;
$ei-nav-pip: \e90d;
$ei-page: \e90e;
$ei-phone: \e90f;
$ei-plus: \e910;
$ei-settings: \e911;
$ei-user: \e912;
$ei-warning: \e913;

// Components - Hero units

$hero-unit-overlay: rgba(0, 0, 0, 0.6);

// Components - Pricing columns

$pricing-border:                 $mid-grey;
$pricing-item-border:            $mid-grey;
$pricing-recommended-background: #ea5adb;
$pricing-recommended-text-color: #ffffff;

// Components - Profiles

// Components - Testimonials

// Components - Icon boxes

$icon-box-icon: $brand-color-primary;
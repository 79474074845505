/*
DESKTOP NAVIGATION
*/

.desktop-nav {
    float: right;
    display: block;
    .nav, .is-submenu {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .nav-item {
        display: block;
        float: left;
        line-height: $header-size;
        &:last-child .is-submenu .is-submenu {
            right: 100%;
            left: auto;
        }
        a {
            text-decoration: none;
            display: block;
            align-items: center;
            padding: 0 10px 0;
            font-size: $desktop-nav-font-size;
            color: $top-level-nav-color;
            @include transition();
            &:hover {
                color: $top-level-nav-color-hover;
            }
        }
    }
    .is-submenu {
        position: absolute;
        flex-direction: column;
        min-width: 225px;
        white-space: nowrap;
        background-color: $submenu-background-color;
        visibility: hidden;
        opacity: 0;
        @include transition((opacity, visibility));
        box-shadow: 0px 5px 15px -2px rgba(0,0,0,0.75);
        .is-submenu {
            left: 100%;
            top: 0;
        }
        li {
            position: relative;
            line-height: 100%;
            a {
                width: 100%;
                padding: 15px 20px;
                color: $submenu-text-color;
                &:hover {
                    color: $submenu-text-color-hover;
                }
            }
        }
    }
    .has-submenu:hover > .is-submenu {
        visibility: visible;
        opacity: 1;
    }
}

.primary-header--resized .desktop-nav .nav-item {
    line-height: $header-size-scroll;
    a {
        color: $top-level-nav-color-scroll;
        &:hover {
            color: $top-level-nav-color-scroll-hover;
        }
    }
}
/*
COLOUR SCHEME
*/

@each $bg-number, $bg-color in
    (primary, $brand-color-primary),
    (secondary, $brand-color-secondary),
    (tertiary, $brand-color-tertiary),
    (light-grey, $light-grey),
    (mid-grey, $mid-grey),
    (dark-grey, $dark-grey),
    (solid-white, $white),
    (solid-black, $black), {
    .background-color-#{$bg-number} {
        background-color: $bg-color;
    }
}

@each $text-number, $text-color in
    (primary, $brand-color-primary),
    (secondary, $brand-color-secondary),
    (tertiary, $brand-color-tertiary),
    (light-grey, $light-grey),
    (mid-grey, $mid-grey),
    (dark-grey, $dark-grey), 
    (solid-white, $white), 
    (solid-black, $white), {
    .text-#{$text-number} {
        color: $text-color;
    }
}


/*
TYPOGRAPHY
*/

html {
    font-size: $base-text-size;
}

body {
    font-family: $body-font;
    font-size: 1rem;
    line-height: 1.6;
    color: $body-text-color;
}

ul, ol {
    margin-left: 0;
}

p, ul, ol, table {
    margin: 0 0 1.6rem 0;
}

.text-small {
    font-size: 0.6rem;
}

.no-list-padding {
    padding-left: 16px;
}

.no-list-style {
    padding-left: 0;
    list-style-type: none;
}

.list {
    &-style-1 {
        li {
            padding: 0 0 10px 24px;
            &:before {
                content: "";
                width: 8px;
                height: 8px;
                background-color: $brand-color-primary;
                display: block;
                position: absolute;
                left: 18px;
                margin-top: 6px;
            }
        }
    }
}

.table {
    border-collapse: collapse;
    width: 100%;
    tr:nth-child(even) {
        background-color: #FAFAFA;
    }
    th, td {
        border: 1px solid #cccccc;
        padding: 10px;
    }
}

.underline {
    position: relative;
    &:after {
        width: 100%;
        height: 1px;
        content: "";
        border-bottom: 1px solid $brand-color-primary;
        padding-top: 20px; 
        display: block;
    }
}

a {
    color: $link-color;
    text-decoration: none;
    @include transition(all);
    &:hover {
        color: $link-color-hover;
    }
}

@for $i from 1 through 6 {
    h#{$i} {
        font-size: $base-heading-size + $heading-scale * (6 - $i);
    }
}

@for $i from 1 through 6 {
    h#{$i} {
        font-family: $heading-font;
        margin: 0 0 1.6rem 0;
        line-height: 1;
        color: $headings-color;
    }
}

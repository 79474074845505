/*
NOTICES
*/

.notice {
    &-wrapper {
        width: 100%;
        overflow: hidden;
        position: fixed;
        padding: 40px 0 40px;
        bottom: 0;
        background: transparent;
    }
    max-width: ($grid-width - 30px);
    border: 1px solid $mid-grey;
    padding: 10px;
    box-shadow: 0px 0px 50px -10px rgba(0,0,0,0.25);
    display: none;
    background-color: $white;
    margin: auto;
}
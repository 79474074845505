/*
GRID
*/

.grid-row {
    display: flex;
    flex-wrap: wrap;
    max-width: $grid-width;
    margin-left: auto;
    margin-right: auto;
    &:after {
        display: table;
        clear: both;
        content: "";
    }
    .grid-row {
        margin-left: -15px;
        margin-right: -15px;
        width: auto;
    }
}

.column {
    flex: 1;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}

[class*='column-'] {
    position: relative;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}

@for $i from 1 through $grid-columns {
    .column-#{$i} {
        width: 100% / $grid-columns * $i;
    }
}

@for $i from 1 through $grid-columns {
    .push-column-#{$i} {
        margin-left: 100% / $grid-columns * $i;
    }
}

@include breakpoint(large) {
    @for $i from 1 through $grid-columns {
        .column-lg-#{$i} {
            width: 100% / $grid-columns * $i;
        }
        .column-lg-#{$i} {
            flex: none;
        }
    }
}

@include breakpoint(medium) {
    @for $i from 1 through $grid-columns {
        .column-#{$i}, .column {
            width: 100%;
        }
        .column {
            flex: none;
        }
        [class*="push-column-"] {
            margin-left: 0px;
        }
    }
}

@include breakpoint(medium) {
    @for $i from 1 through $grid-columns {
        .column-md-#{$i} {
            width: 100% / $grid-columns * $i;
        }
    }
}

@include breakpoint(small) {
    @for $i from 1 through $grid-columns {
        .column-sm-#{$i} {
            width: 100% / $grid-columns * $i;
        }
    }
}

@include breakpoint(xsmall) {
    @for $i from 1 through $grid-columns {
        .column-xs-#{$i} {
            width: 100% / $grid-columns * $i;
        }
    }
}

@each $hide-media in large, medium, small, xsmall {
    @include breakpoint($hide-media){
        .hide-#{$hide-media} {
            display: none;
        }
    }
}
@mixin breakpoint($point) {
    @if $point == large {
        @media #{$breakpoint-large} { @content; }
    }
    @else if $point == medium {
        @media #{$breakpoint-medium} { @content; }
    }
    @else if $point == small {
        @media #{$breakpoint-small}  { @content; }
    }
    @else if $point == xsmall {
        @media #{$breakpoint-xsmall}  { @content; }
    }
}

@mixin transition($property: all, $duration: 350ms, $ease: ease-in-out){
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $ease;
}
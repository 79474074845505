/*
CARDS
*/

.card {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid $mid-grey;
    @include transition((box-shadow), 350ms, ease-in-out);
    &--rounded-corners {
        border-radius: 6px;
    }
    &__header {
        padding: 20px;
        position: relative;
        &:after {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: #ffffff;
            bottom: -10px;
            z-index: 20;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
        * {
            margin: 0;
        }
    }
    &__image {
        border-bottom: 1px solid $mid-grey;
        position: relative;
        img {
            display: block;
        }
    }
    &__content {
        padding: 20px;
    }
    &__meta {
        padding: 20px;
        border-top: 1px solid $mid-grey;
        a {
            color: #303030;
            font-size: 0.8rem;
        }
    }
}
/*
TABS
*/

.tabs {
    margin-bottom: 1rem;
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        &-tab {
            display: inline-block;
            padding: 14px;
            cursor: pointer;
            background-color: $light-grey;
            margin-bottom: -1px;
            @include transition((background-color, color), 350ms, ease-in-out);
            border-left: 1px solid $mid-grey; 
            border-right: 1px solid $mid-grey; 
            border-top: 1px solid $mid-grey;
            &:hover {
                background-color: $mid-grey;
                color: #303030;
            }
            &-active {
                background-color: $white;
                &:hover {
                    background-color: $white;
                    color: #303030;
                }
            }
        }
    }
    &__content {
        overflow: hidden;
        height: 0;
        opacity: 0;
        @include transition(900ms, opacity);
        &-active {
            display: block;
            padding: 15px;
            height: auto;
            border: 1px solid $mid-grey;
            opacity: 1;
        }
    }
    &--style-2 {
        .tabs {
            &__list {
                float: left;
                &-tab {
                    display: block;
                    border-left: 1px solid $mid-grey; 
                    border-bottom: 1px solid $mid-grey; 
                    border-top: 1px solid $mid-grey;
                    border-right: 1px solid transparent;
                    margin-right: -1px;
                }
            }
        }
    }
}
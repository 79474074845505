/*
ACCORDION
*/

.accordion {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    &__header {
        position: relative;
        margin-bottom: 1px;
        padding: 15px;
        width: 100%;
        background-color: $mid-grey;
        cursor: pointer;
        @include transition((background-color), 350ms, ease-in-out);
        &:hover {
            background-color: darken( $mid-grey, 10%);
        }
        &:after {
            position: absolute;
            right: 12px;
            content: "\e910";
            font-size: 18px;
            font-family: "ensemble-icons";
            @include transition((transform), 350ms, ease-in-out);
        }
    }
    &__active:after {
        position: absolute;
        right: 12px;
        content: "\e910";
        font-size: 18px;
        font-family: "ensemble-icons";
        transform: rotate(45deg);
    }
    &__content {
        display: none;
        padding: 20px 15px 5px 15px;
    }
    &--style-2 {
        .accordion {
            &__header {
                background-color: $white;
                border-bottom: 1px solid $mid-grey;
                padding: 15px 0 15px;
                &:after {
                    right: 0px;
                }
            }
        }
    }
}
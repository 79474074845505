/*
MOBILE NAVIGATION
*/

.mobile-nav-toggle {
    display: none;
}

@include breakpoint(large) {
    .desktop-nav {
        display: none;
    }
    .mobile-nav-toggle {
        position: relative;
        z-index: 200;
        display: block;
        overflow: hidden;
        width: 30px !important;
        border: none;
        border-radius: none;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        height: 25px;
        &:focus {
            outline: 0;
        }
        & span {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            display: block;
            height: 2px;
            transition: background 0s 0.3s;
            transform: translateY(-50%);
            background-color: $mobile-nav-toggle;
            &:before, &:after {
                position: absolute;
                left: 0;
                display: block;
                width: 100%;
                height: 2px;
                content: "";
                transition-delay: 0.3s, 0s;
                transition-duration: 0.3s, 0.3s;
                background-color: $mobile-nav-toggle;
            }
            &:before {
                top: -10px;
                transition-property: top, transform;
            }
            &:after {
                bottom: -10px;
                transition-property: bottom, transform;
            }
        }
        &__is-active span {
            background: none;
            &:before {
                top: 0;
                transition-delay: 0s, 0.3s;
                transform: rotate(45deg);
            }
            &:after {
                bottom: 0;
                transition-delay: 0s, 0.3s;
                transform: rotate(-45deg);
            }
        }
    }
    .mobile-nav {
        position: fixed;
        width: $mobile-nav-width;
        height: 100%;
        background-color: $mobile-nav-background;
        box-shadow: 0px 0px 23px -2px rgba(48,48,48,0.7);
        left: -100%;
        top: 0;
        overflow-y: auto;
        @include transition((left), 500ms, ease-in-out);
        .nav {
            list-style-type: none;
            padding: 0;
            li {
                list-style-type: none;
                width: 100%;
                float: left;
                clear: both;
                cursor: pointer;
                position: relative;
                border-bottom: 1px solid $mobile-navigation-bottom-border;
                a {
                    display: inline-block;
                    padding: 12px 20px;
                    color: $mobile-nav-color;
                }
            }
            .has-submenu {
                list-style-type: none;
                &:before {
                    position: absolute;
                    font-family: "ensemble-icons";
                    content: "\e902";
                    line-height: 50px;
                    padding: 0 10px;
                    right: 0;
                }
            }
        }
        &--slide-in {
            left: 0;
        }
        .is-submenu {
            padding: 0;
            margin: 0;
            max-height: 0;
            overflow: hidden;
            @include transition((max-height), 850ms, ease-in-out);
        }
        .open > .is-submenu {
            transition-delay: 0ms;
            max-height: 5000px;
        }
    }
}
/*
HEADER
*/

.main-content {
    background-color: $white;
    position: relative;
}

.primary-header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $bottom-border;
    background-color: $header-background-color;
    height: $header-size;
    align-items: center;
    @include transition((background-color, color, height), 350ms, linear);
    &--border {
        width: 100%;
        display: block;
        border-bottom: 1px dashed $white;
        margin: 0 15px 0;
    }
    &--fixed-position {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000
    }
    &__logo {
        visibility: visible;
        opacity: 1;
        @include transition((all), 500ms, ease-in-out);
        transform: translateY(-50%);
        position: absolute;
        & * {
            margin: auto 0;
            float: left;
        }
        &--alternate {
            visibility: hidden;
            opacity: 0;
            transform: translateY(100%);
        }
    }
    &--resized {
        height: $header-size-scroll;
        background-color: $header-background-color-scroll;
        border-bottom: 1px solid $bottom-border-scroll;
        .primary-header__logo {
            transform: translateY(-100%);
            visibility: hidden;
            opacity: 0;
        }
        .primary-header__logo--alternate {
            visibility: visible;
            opacity: 1;
            transform: translateY(-50%);
        }
        .primary-header--border {
            display: none;
        }
    }
}
/*
FONTS
*/

@font-face {
    font-family: 'ensemble-icons';
    src:
        url('#{$ensemble-font-path}/ensemble-icons.ttf?95kl09') format('truetype'),
        url('#{$ensemble-font-path}/ensemble-icons.woff?95kl09') format('woff'),
        url('#{$ensemble-font-path}/ensemble-icons.svg?95kl09#ensemble-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="ei-"], [class*=" ei-"] {
    font-family: 'ensemble-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.3rem;
    vertical-align: text-bottom;
}

@function unicode($str) {
    @return unquote("\"") + $str + unquote("\"");
}

.ei-address {
    &:before {
        content: unicode($ei-address); 
    }
}
.ei-calendar {
    &:before {
        content: unicode($ei-calendar); 
    }
}
.ei-chevron-down {
    &:before {
        content: unicode($ei-chevron-down); 
    }
}
.ei-chevron-left {
    &:before {
        content: unicode($ei-chevron-left); 
    }
}
.ei-chevron-right {
    &:before {
        content: unicode($ei-chevron-right); 
    }
}
.ei-chevron-up {
    &:before {
        content: unicode($ei-chevron-up); 
    }
}
.ei-close {
    &:before {
        content: unicode($ei-close); 
    }
}
.ei-comment {
    &:before {
        content: unicode($ei-comment); 
    }
}
.ei-email {
    &:before {
        content: unicode($ei-email); 
    }
}
.ei-help {
    &:before {
        content: unicode($ei-help); 
    }
}
.ei-magnifier {
    &:before {
        content: unicode($ei-magnifier); 
    }
}
.ei-mobile-phone {
    &:before {
        content: unicode($ei-mobile-phone); 
    }
}
.ei-nav-pip-active {
    &:before {
        content: unicode($ei-nav-pip-active); 
    }
}
.ei-nav-pip {
    &:before {
        content: unicode($ei-nav-pip); 
    }
}
.ei-page {
    &:before {
        content: unicode($ei-page); 
    }
}
.ei-phone {
    &:before {
        content: unicode($ei-phone); 
    }
}
.ei-plus {
    &:before {
        content: unicode($ei-plus); 
    }
}
.ei-settings {
    &:before {
        content: unicode($ei-settings); 
    }
}
.ei-user {
    &:before {
        content: unicode($ei-user); 
    }
}
.ei-warning {
    &:before {
        content: unicode($ei-warning); 
    }
}


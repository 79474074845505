/*
TESTIMONIALS
*/

.testimonial {
    width: 100%;
    float: left;
    overflow: hidden;
    &__quote {
        position: relative;
        margin-bottom: 40px; 
        background-color: #ffffff;
        blockquote {
            margin: 0;
            padding: 20px;
            border: 1px solid $mid-grey;
        }
        &:after {
            content: "";
            width: 22px;
            height: 22px;
            border-left: 1px solid $mid-grey;
            border-bottom: 1px solid $mid-grey;
            display: block;
            position: absolute;
            transform: rotate(-45deg);
            left: 40px;
            bottom: -11px;
            background-color: inherit;
        }
    }
    &__image {
        max-width: 100px;
        max-height: 100px;
        float: left;
        &--round img {
            border-radius: 120px;
        }
    }
    &__meta {
        float: left;
        margin-left: 30px;
        &-name {
            font-weight: 600;
            color: #303030;
            width: 100%;
            display: block;
        }
        &-position {

        }
    }
}
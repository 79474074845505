/*
ALERTS
*/

.alert {
    width: 100%;
    padding: 0.8rem 1.2rem;
    position: relative;
    border-radius: 4px;
    p {
        margin: 0;
    }
    &--warning {
        background-color: $warning-background;
        color: $warning-text-color;
        border: 1px solid $warning-border;
    }
    &--success {
        background-color: $success-background;
        color: $success-text-color;
        border: 1px solid $success-border;
    }
    &--danger {
        background-color: $danger-background;
        color: $danger-text-color;
        border: 1px solid $danger-border;
    }
    &__close {
        position: absolute;
        padding: 0 20px;
        right: 0;
        top: 0;
        cursor: pointer;
        &:after {
            font-family: "Fontawesome";
            content: "\f00d";
            line-height: 49px;
        } 
    }
}
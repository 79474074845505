/*
HERO UNITS
*/

.hero-unit {
    width: 100vw;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: inherit;
    background-size: cover;
    background-position: center;
    &__background {
        background-image: url("../img/doxs-homepage-banner.jpg");
    }
    &__content {
        position: relative;
        text-align: center;
        z-index: 10;
        h2 {
            color: $white;
        }
    }
    
    &--overlay {
        background-color: $hero-unit-overlay;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }
    &--parallax{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}

.offscreen-hero-unit {
    width: 100%;
    float: left;
    &__content {
        width: 50%;
        float: left;
        display: flex;
        flex-direction: column;
    }
    &__image {
        width: 50%;
        float: left;
    }
    &__image-right, &__image-left {
        width: 175%;
        img {
            width: inherit;
            display: block;
        }
    }
    &__image-left {
        float: right;
    }
}

@include breakpoint(medium) {
    .offscreen-hero-unit {
        display: block !important;
        &__content, &__image {
            width: 100%;
        }
        &__content {
            padding: 100px 0 80px 0;
        }
        &__image-right, &__image-left {
            width: auto;
            margin-right: -15px;
            margin-left: -15px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
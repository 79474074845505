/*
ICON BOXES
*/

.icon-box {
    position: relative;
    &--bordered {
        border: 1px solid $mid-grey;
    }
    &__icon {
        width: 60px;
        height: 60px;
        background-color: $white;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 60px;
        color: $icon-box-icon;
        &--small {
            font-size: 1.9rem;
        }
        &--medium {
            font-size: 2.3rem;
        }
        &--large {
            font-size: 3rem;
        }
    }
    &__content {
        padding: 40px 20px 0px;
    }
}

.icon-box--icon-left {
    .icon-box {
        &__icon {
            position: absolute;
            float: left;
            left: 0px;
            transform: none;
            height: auto;
            width: auto;
            line-height: 1;
        }
        &__content {
            padding: 0 0 0 70px;
            position: relative;
            &:after {
                content: "";
                border-left: 1px solid $mid-grey;
                position: absolute;
                left: 50px;
                height: 100%;
                top: 0;
            }
        }
    }
}
/*
PARALLAX
*/

.parallax {
    width: 100%;
    overflow: hidden;
    position: relative;
    &--background {
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 130%;
    }
}
/*
HELPERS
*/

@each $float-direction in left, right, none {
    .float-#{$float-direction} {
        float: $float-direction;
    }
}

@each $txt-direction in left, right, center, justify {
    .text-#{$txt-direction} {
        text-align: $txt-direction;
    }
}

@each $align-direction in top, middle, bottom {
    .align-#{$align-direction} {
        vertical-align: $align-direction;
    }
}

@each $txt-transform in uppercase, lowercase, capitalize {
    .text-#{$txt-transform} {
        text-transform: $txt-transform;
    }
}

@each $position in absolute, relative, fixed {
    .position-#{$position} {
        position: $position;
    }
}

@each $radius, $amount in
    (small, 0.3rem),
    (medium, 0.5rem),
    (large, 0.9rem) {
    .border-radius-#{$radius} {
        border-radius: $amount;
    }
}

.bold {
    font-weight: 600;
}

.hide {
    display: none;
}

.clear:after {
    display: table;
    clear: both;
    content: "";
}

.display-flex {
    display: flex;
}

.center-vertical {
    display: flex;
    align-items: center;
    height: 100%;
}

.center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}

$max: 100;
$step: 10;

@for $i from 1 through ceil($max/$step) {
    $value: ($i)*$step;
    .section-height-#{$value} { height: $value+(vh); }
}
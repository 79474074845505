/*
FOOTER
*/

.footer {
    background-color: #303030;
    padding: 100px 0 80px;
    &__copyright-info {
        padding: 10px 0 10px;
        border-top: 1px solid rgba(255,255,255, 0.2);
    }
    &--fixed {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
    }
}
.promo-box {
    border: 1px solid #cccccc;
    position: relative;
    float: left;
    width: 100%;
    @include transition(all);
    &:hover {
        background-color: $white;
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    }
    &__content {
        float: left;
        width: 100%;
        padding: 20px;
    }
    &__button {
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        transform: translateY(50%);
    }
}
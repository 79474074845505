/*
IMAGE OVERLAYS
*/

a.image-overlay {
    display: block;
    position: relative;
}
.image-overlay {
    position: relative;
    overflow: hidden;
    img {
        display: block;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255, 0.8);
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include transition((all), 350ms, ease-in-out);
    }
    &:hover .image-overlay__background {
        opacity: 1;
        visibility: visible;
    }
    &--skew {
        img {
            @include transition((transform), 350ms, ease-in-out);
        }
        &:hover img {
            transform: rotate(-10deg) scale(1.3);
        }
    }
    &--zoom {
        img {
            @include transition((transform), 350ms, ease-in-out);
        }
        &:hover img {
            transform: scale(1.3);
        }
    }
    &--slide-left {
        .image-overlay__background {
            position: absolute;
            left: -100%;
        }
        &:hover .image-overlay__background {
            left: 0;
        }
    }
    &--slide-right {
        .image-overlay__background {
            position: absolute;
            right: -100%;
            left: auto;
        }
        &:hover .image-overlay__background {
            right: 0;
        }
    }
    &--grow-in {
        .image-overlay__background {
            transform: scale(0)
        }
        &:hover .image-overlay__background {
            transform: scale(1);
        }
    }
}
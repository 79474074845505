/*
PRICING
*/

.pricing-column {
    border: 1px solid $pricing-border;
    padding: 1.4rem;
    box-shadow: 0px 2px 8px -1px rgba(48,48,48,0.2);
    width: 100%;
    &__package {
        h6 {
            color: #303030;
            text-align: center;
        }
    }
    &__price {
        p {
            text-align: center;
            font-size: 3rem;
            color: #303030;
        }
    }
    &__items {
        ul {
            list-style-type: none;
            text-align: center;
            margin: 0;
            padding: 0;
            li {
                border-bottom: 1px solid $pricing-item-border;
                margin-bottom: 0.8rem;
            }
        }
    }
    &__button {
        text-align: center;
        padding-top: 1.2rem;
    }
    &--recommended {
        background-color: $pricing-recommended-background;
        box-shadow: 0px 0px 25px -4px rgba(48,48,48,1);
        .pricing-column {
            &__package h6, &__price p, &__items ul li {
                color: $pricing-recommended-text-color;
            }
        }
    }
}
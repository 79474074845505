/*
FORMS
*/

.form {
    width: 100%;
    float: left;
    clear: both;
    label {
        width: 100%;
        float: left;
        clear: both;
        margin-bottom: 5px;
    }
    input, textarea {
        width: 100%;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid $mid-grey;
        margin-bottom: 1.6rem;
        @include transition((all), 350ms, ease-in-out);
        &:focus {
            border-color: $brand-color-primary;
            outline: 0;
            box-shadow: 0 0 5px 0.2rem rgba($brand-color-primary,.30);
        }
    }
}


/*
PROFILES
*/

.profile {
    &__image {
        float: left; 
        border: 1px solid $mid-grey;
        margin-bottom: 10px;
        img {
            display: block;
        }
        i {
            color: #303030;
            font-size: 1.8rem;
            padding: 4px;
        }
    }
    &__meta {
        border-bottom: 1px solid $mid-grey;
        margin-bottom: 10px;
        &-name {
            color: #303030;
            font-weight: 600;
            &:after {
                content: "/";
                margin: 0 4px 0 8px;
                color: #909090;
                font-weight: normal;
            }
        }
    }
    &__bio {
        float: left;
    }
}